import {IncomingMessage} from "http";

import isbot from "isbot";
import MobileDetect from "mobile-detect";
import {Dispatch} from "redux";
import {AppDispatch, actions} from "src/store";

import {CarbonEnvs} from "../../constants/urls";
import {ConfigState, RegionSlug} from "../../store/types";
import {fetchLocationsAndPractices} from "../../utils/fetchLocationsAndPractices";
import {getCarbonHost} from "../../utils/urls";
import {dev} from "./_constants";
import {getCookie} from "./Carbon";

const GOOGLE_API_KEY_DEV = process.env.GOOGLE_API_KEY_DEV;
const GOOGLE_API_KEY_PROD = process.env.GOOGLE_API_KEY_PROD;
const GOOGLE_API_KEY_PROD_FOR_TESTINGCENTERS = process.env.GOOGLE_API_KEY_PROD_FOR_TESTINGCENTERS;

export const setPublicGoogleKeys = (dispatch: AppDispatch) =>
  dispatch(
    actions.setConfig({
      googleApiKey: dev ? GOOGLE_API_KEY_DEV : GOOGLE_API_KEY_PROD,
      googleApiKeyForTestingCenters: dev
        ? GOOGLE_API_KEY_DEV
        : GOOGLE_API_KEY_PROD_FOR_TESTINGCENTERS,
    }),
  );

const isPracticeDataSet = (config: ConfigState) =>
  Boolean(config.allSpecialties && config.locations.length && config.practices);

export const setPracticeData = async (config: ConfigState, dispatch: AppDispatch) => {
  if (!isPracticeDataSet(config)) {
    const [{practices, locations}, {default: allSpecialties}] = await Promise.all([
      fetchLocationsAndPractices(),
      import("../../../public/static/data/localSpecialties.json"),
    ]);

    dispatch(
      actions.setConfig({
        practices,
        locations,
        allSpecialties,
      }),
    );
  }
};

const RECAPTCHA_CLIENT_KEY = process.env.RECAPTCHA_CLIENT_KEY;
const TALKDESK_FLOW_ID = process.env.TALKDESK_FLOW_ID || "";

type SetBootStateParams = {
  dispatch: Dispatch;
  req: IncomingMessage;
  query: Record<string, unknown>;
};

export const setBootState = ({dispatch, req, query}: SetBootStateParams): void => {
  let imgExt = "webp";

  const browser = ((req || {}).headers || {})["user-agent"];
  // @ts-expect-error TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  const md = new MobileDetect(browser);
  const isMobile = !!md.mobile() || !!md.tablet(); // doesnt detect ipad / ipad and safari is same

  // @ts-expect-error TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  const supportsWebp = /Chrome|Opera|OPR|Firefox|Edge|Samsung/i.test(browser);
  if (!supportsWebp) imgExt = "jpg";

  const isBot = isbot(browser);

  const selectedRegion =
    (query.regionSlug as RegionSlug) || (getCookie({req, name: "selectedRegion"}) as RegionSlug);

  dispatch(
    actions.setConfig({
      // @ts-expect-error TS2532, TS2339, TS2532, TS2339: Object is possibly 'undefined'.,  Property 'csrfToken' does not exist on type 'IncomingMessage'.,  Object is possibly 'undefined'.,  Property 'csrfToken' does not exist on type 'IncomingMessage'.
      csrfToken: typeof req.csrfToken === "function" ? req.csrfToken() : null,
      referer: req.headers.referer,
      imgExt,
      isMobile,
      // @ts-expect-error TS2532, TS2345: Object is possibly 'undefined'.,  Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      host: getCarbonHost(req.headers.host) || CarbonEnvs.prod,
      pageLoaded: false,
      locationsSorted: false,
      recaptchaClientKey: RECAPTCHA_CLIENT_KEY,
      talkdeskFlowId: TALKDESK_FLOW_ID,
      isBot,
    }),
  );

  dispatch(actions.setUserLocation({selectedRegion}));
};
